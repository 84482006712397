.form-error {
  color: #dc3545;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  padding: 0.25rem 0;
  text-align: left;
  opacity: 0;
  animation: fadeIn 0.2s ease-in forwards;
  display: flex;
  align-items: center;
}

.form-error::before {
  content: "⚠️";
  margin-right: 0.5rem;
  font-size: 1rem;
}

.form-error-text {
  line-height: 1.2;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
