.amount-input::-webkit-outer-spin-button,
.amount-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.currency-option.is-invalid,
.payment-option.is-invalid {
  border-color: #dc3545 !important;
}

.currency-option.is-invalid:hover,
.payment-option.is-invalid:hover {
  border-color: #dc3545 !important;
}

.currency-option.active.is-invalid,
.payment-option.active.is-invalid {
  border-color: #dc3545 !important;
  background-color: rgba(220, 53, 69, 0.1) !important;
}

.amount-wrapper.is-invalid {
  border-color: #dc3545 !important;
}

.amount-wrapper.is-invalid:focus-within {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
}

.months-input-wrapper .form-control.is-invalid {
  border-color: #dc3545 !important;
}

.months-input-wrapper .form-control.is-invalid:focus {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
}
