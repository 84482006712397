.ad-request-page {
  width: 100%;
  background-color: #f5f7fa;
  position: relative;
  margin-top: 60px;
  overflow: hidden;
}

.ad-request-container {
  max-width: 900px;
  margin: 0 auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 16px;
  max-width: 900px;
  margin: 0 auto;
}

.page-header-content {
  display: flex;
  align-items: center;
  gap: 16px;
}

.page-header h1 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #0b3357;
}

.back-button {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: none;
  border: none;
  color: #0b3357;
  font-weight: 500;
  font-size: 0.95rem;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.back-button:hover {
  background-color: rgba(11, 51, 87, 0.05);
}

.back-button::before {
  content: "←";
  margin-right: 8px;
  font-size: 1.1rem;
}

@media (max-width: 768px) {
  .ad-request-page {
    height: calc(100vh - 60px);
    padding: 0;
    margin-top: 60px; /* Mantener el margen superior en móvil */
  }
  
  .page-header {
    padding: 15px;
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    background: #f8fafc;
    z-index: 100;
  }
  
  .page-header h1 {
    font-size: 1.3rem;
  }
  
  .back-button {
    padding: 6px 12px;
    font-size: 0.9rem;
  }

  .ad-request-container {
    margin-top: 60px; /* Espacio para el header fijo en móvil */
  }
}
