:root {
  --border-radius-small: 8px;
  --border-radius-medium: 12px;
  --border-color-default: #e2e8f0;
  --padding-default: 1rem;
  --margin-bottom-default: 1rem;
  --margin-top-default: 1rem;
  --margin-left-indent: 1.75rem;
}

/* Common form elements styling */

.toast-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
}

.toast {
  background: white;
  border: none !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
  min-width: 300px;
}

.toast-header {
  background-color: #1b74c5 !important;
  color: white !important;
  border: none !important;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.toast-header strong {
  color: white !important;
  font-size: 1.1em;
}

.toast-body {
  font-family: "Montserrat", sans-serif;
  font-size: 1em;
  padding: var(--padding-default);
  text-align: center;
  color: #333;
}

.form-section {
  background: #ffffff;
}

.form-section-title {
  color: #0b3357;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 24px;
}

.form-group:last-child {
  margin-bottom: 0;
}

.form-label {
  display: block;
  color: #2d3748;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 0.95rem;
}

/* Common input styles */
.form-control,
input[type="text"],
input[type="number"],
input[type="date"],
select,
textarea {
  width: 100%;
  padding: 12px 16px;
  border: 2px solid var(--border-color-default);
  border-radius: var(--border-radius-small);
  font-size: 0.95rem;
  color: #2d3748;
  background-color: #ffffff;
  transition: all 0.2s ease;
}

.form-control:focus,
input[type="text"]:focus,
input[type="number"]:focus,
input[type="date"]:focus,
select:focus,
textarea:focus {
  border-color: #0b3357;
  box-shadow: 0 0 0 3px rgba(11, 51, 87, 0.1);
  outline: none;
}

input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}

/* Radio and Checkbox styles */
.form-check {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  border-radius: var(--border-radius-medium);
  margin-bottom: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.form-check:last-child {
  margin-bottom: 0;
}

.form-check.active {
  border-color: #0b3357;
  background-color: rgba(11, 51, 87, 0.03);
}

.form-check-input {
  margin-right: 12px;
  margin-top: 4px;
}

.form-check-label {
  flex: 1;
}

.option-header {
  font-weight: 500;
  color: #2d3748;
  font-size: 0.95rem;
  margin: 0 auto;
}

.option-description {
  color: #718096;
  font-size: 0.9rem;
  line-height: 1.4;
}

/* Custom input wrapper */
.custom-input-wrapper {
  margin-top: 12px;
  margin-left: 28px;
  padding-right: 12px;
  width: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.custom-input {
  background-color: #ffffff !important;
  border: 2px solid #e2e8f0 !important;
  border-radius: 8px !important;
  padding: 10px 16px !important;
  font-size: 0.95rem !important;
  width: 100% !important;
  transition: all 0.2s ease !important;
  color: #2d3748 !important;
}

.custom-input:focus {
  border-color: #0b3357 !important;
  box-shadow: 0 0 0 3px rgba(11, 51, 87, 0.1) !important;
  outline: none !important;
}

.custom-input::placeholder {
  color: #a0aec0 !important;
  font-size: 0.9rem !important;
}

/* Ensure the input doesn't trigger the parent's hover effect */
.audience-option .custom-input-wrapper {
  position: relative;
  z-index: 2;
}

.audience-option:hover .custom-input {
  border-color: #e2e8f0 !important;
}

.audience-option .custom-input:focus {
  border-color: #0b3357 !important;
}

.audience-options .audience-option.is-invalid {
  border-color: #dc3545;
}

.audience-options .audience-option.is-invalid:hover {
  border-color: #dc3545;
}

.audience-options .audience-option .custom-input.is-invalid {
  border-color: #dc3545;
}

/* Currency selector styles */
.currency-selector {
  margin-bottom: 24px;
  width: 50%;
  margin: 0 auto;
}

.currency-options {
  display: flex;
  gap: 12px;
  margin-bottom: 20px;
}

.currency-option {
  flex: 1;
  text-align: center;
  padding: 16px;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.currency-option:hover {
  border-color: #cbd5e0;
  background-color: #f8fafc;
}

.currency-option.active {
  border-color: #0b3357;
  background-color: rgba(11, 51, 87, 0.03);
}

.currency-option.is-invalid {
  border-color: #dc3545;
}

/* Amount input styles */
.amount-wrapper {
  position: relative;
  max-width: 300px;
  margin: 0 auto;
}

.currency-symbol {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #4a5568;
  font-weight: 500;
}

.amount-input {
  padding-left: 40px !important;
  text-align: right;
  font-weight: 500;
}

.amount-input.is-invalid {
  border-color: #dc3545;
}

/* Location selector styles */
.location-selector {
  margin-bottom: 24px;
}

.location-selector {
  min-width: 200px;
  margin-left: 0.5rem;
  border-color: #198754;
  color: #198754;
}

.location-selector:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.2rem rgba(25, 135, 84, 0.25);
}

.department-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 12px;
  margin-bottom: 24px;
}

.department-card {
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.department-card:hover {
  border-color: #cbd5e0;
  background-color: #f8fafc;
}

.department-card.active {
  border-color: #0b3357;
  background-color: rgba(11, 51, 87, 0.03);
}

.department-name {
  font-weight: 500;
  color: #2d3748;
  font-size: 0.95rem;
}

/* Date selector styles */
.date-selector {
  margin-bottom: 24px;
}

/* Error message styles */
.error-message {
  color: #e53e3e;
  font-size: 0.85rem;
  margin-top: 6px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .progress-bar-bottom {
    margin-bottom: 30px;
  }
  .form-check {
    padding: 12px;
  }

  .currency-options {
    flex-direction: column;
  }

  .currency-option {
    padding: 12px;
  }

  .department-grid {
    grid-template-columns: 1fr;
  }
}

.ad-request-form-container {
  margin-top: 110px;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
}

.ad-request-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: 100%;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.form-progress-bar {
  padding: 20px;
  background: #ffffff;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}

.progress-steps-container {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 10px;
}

.navigation-buttons {
  display: flex;
  gap: 8px;
}

.navigation-button {
  padding: 8px 12px !important;
  min-width: auto !important;
  font-size: 0.85rem !important;
}

.steps-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
  min-width: 0;
}

@media (min-width: 768px) {
  .steps-wrapper {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}

.steps-wrapper::before {
  content: "";
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  height: 2px;
  background: #e2e8f0;
  z-index: 1;
}

.step-item {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  flex: 1;
  min-width: 0;
  padding: 0 4px;
  transition: all 0.3s ease;
}

.step-number {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ffffff;
  border: 2px solid #e2e8f0;
  color: #6b7c93;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 600;
  transition: all 0.3s ease;
}

.step-indicator {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ffffff;
  border: 2px solid #e2e8f0;
  color: #6b7c93;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  font-weight: 600;
  transition: all 0.3s ease;
}

.step-indicator.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.step-indicator.disabled:hover {
  background-color: inherit;
}

.step-item.active .step-number {
  background: #0b3357;
  border-color: #0b3357;
  color: white;
  transform: scale(1.1);
}

.step-item.completed .step-number {
  background: #0b3357;
  border-color: #0b3357;
  color: white;
}

.step-title {
  font-size: 0.8rem;
  color: #6b7c93;
  margin: 0;
  font-weight: 500;
  text-align: center;
  transition: all 0.3s ease;
  display: none;
}

.step-item.active .step-title {
  display: block;
  color: #0b3357;
  font-weight: 600;
  margin-top: 4px;
}

/* Form content styling */
.form-content {
  flex: 1;
  overflow-y: auto;
  background: #ffffff;
  padding-left: 1rem;
  padding-right: 1rem;
}

.form-content > * {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

/* Scrollbar styling */
.form-content::-webkit-scrollbar {
  width: 8px;
}

.form-content::-webkit-scrollbar-track {
  background: #f1f5f9;
  border-radius: 4px;
}

.form-content::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 4px;
}

.form-content::-webkit-scrollbar-thumb:hover {
  background: #94a3b8;
}

/* Navigation buttons container */
.mt-4 {
  padding: 16px 24px;
  background: #ffffff;
  border-top: 1px solid rgba(11, 51, 87, 0.08);
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  margin: 0 !important;
  width: 100%;
}

.mt-4 > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 700px;
  margin: 0 auto;
  width: 100%;
  gap: 16px;
}

/* Button styling */
.btn-primary {
  background: #0b3357 !important;
  border: none !important;
  padding: 12px 24px !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
  color: white !important;
  transition: all 0.2s ease !important;
}

.btn-primary:hover:not(:disabled) {
  background: #0d4274 !important;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(11, 51, 87, 0.15);
}

.btn-primary:disabled {
  opacity: 0.6 !important;
  cursor: not-allowed !important;
}

.navigation-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  padding: 12px 24px !important;
  border: 2px solid #0b3357 !important;
  color: #0b3357 !important;
  font-weight: 500 !important;
  font-size: 0.95rem !important;
  cursor: pointer !important;
  border-radius: 8px !important;
  transition: all 0.2s ease !important;
  min-width: 120px;
  gap: 8px;
}

.navigation-button:hover {
  background-color: rgba(11, 51, 87, 0.05) !important;
  transform: translateY(-1px);
}

.navigation-button.primary {
  background-color: #0b3357 !important;
  color: white !important;
}

.navigation-button.primary:hover {
  background-color: #0d4274 !important;
  box-shadow: 0 2px 8px rgba(11, 51, 87, 0.15);
}

.navigation-button.secondary {
  background-color: white !important;
}

.navigation-button.secondary:hover {
  background-color: #f8fafc !important;
}

.navigation-button:disabled {
  opacity: 0.6 !important;
  cursor: not-allowed !important;
  background-color: #e2e8f0 !important;
  border-color: #e2e8f0 !important;
  color: #718096 !important;
}

/* Form sections styling */
.form-section {
  background: #ffffff;
}

.form-section-title {
  color: #0b3357;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 16px;
}

/* Media options styling */
.media-option {
  padding: 15px;
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-small);
  margin-bottom: calc(var(--margin-bottom-default) / 1.333); /* 12px / 1rem */
  transition: all 0.2s ease;
  cursor: pointer;
}

.media-option:hover {
  border-color: #0b3357;
  background: rgba(11, 51, 87, 0.02);
}

.media-option.active {
  border-color: #0b3357;
  background: rgba(11, 51, 87, 0.05);
}

.media-option-title {
  font-weight: 500;
  color: #2d3748;
  margin-bottom: 4px;
}

.media-option-description {
  color: #718096;
  font-size: 0.9rem;
}

/* Form inputs styling */
input[type="text"],
input[type="number"],
input[type="date"],
select,
textarea {
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 1rem;
  transition: all 0.2s ease;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="date"]:focus,
select:focus,
textarea:focus {
  border-color: #0b3357;
  box-shadow: 0 0 0 3px rgba(11, 51, 87, 0.1);
  outline: none;
}

input[type="number"] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}

/* Custom checkbox and radio styling */
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #0b3357;
  border-color: #0b3357;
}

/* Form groups */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  color: #2d3748;
  font-weight: 500;
  margin-bottom: 8px;
  display: block;
}

/* Error messages */
.error-message {
  color: #e53e3e;
  font-size: 0.875rem;
  margin-top: 4px;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .ad-request-form-container {
    padding: 0;
    margin: 20px 0;
    margin-top: 100px !important;
    height: calc(100vh - 40px);
    max-width: 100%;
    width: 100vw;
    padding-bottom: 50px;
  }

  .ad-request-form {
    margin: 0;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    height: 100%;
  }

  .form-progress-bar {
    padding: 1rem;
  }

  .progress-steps-container {
    gap: 10px;
  }

  .step-item {
    max-width: none;
  }

  .step-title {
    font-size: 0.8rem;
  }

  .btn-navigation {
    padding: 0.5rem 1rem;
  }

  .currency-input-group {
    flex-direction: column;
    gap: 0.5rem;
  }

  .currency-input-group select,
  .currency-input-group input {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .step-number {
    width: 24px;
    height: 24px;
    font-size: 0.8rem;
  }

  .step-title {
    display: none !important;
  }

  .form-section-title {
    font-size: 1.1rem;
  }

  .form-check {
    padding: 0.5rem;
  }

  .form-label {
    font-size: 0.9rem;
  }
}

/* Step 1 Styles */
.step-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 20px;
  padding-top: 10px;
}

.section-subtitle {
  text-align: center;
  color: #718096;
  font-size: 0.95rem;
  margin-top: 0.5rem;
}

.description-section {
  margin-bottom: 2rem;
}

.custom-textarea {
  width: 100%;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  padding: 1rem;
  font-size: 1rem;
  resize: vertical;
  min-height: 120px;
  transition: all 0.2s ease;
  background-color: #f8fafc;
}

.custom-textarea.placeholder-small::placeholder {
  font-size: 0.85rem;
  color: #6c757d;
  opacity: 1;
}

.custom-textarea.placeholder-small::-webkit-input-placeholder {
  font-size: 0.85rem;
  color: #6c757d;
}

.custom-textarea.placeholder-small:-ms-input-placeholder {
  font-size: 0.85rem;
  color: #6c757d;
}

.custom-textarea.placeholder-small:-moz-placeholder {
  font-size: 0.85rem;
  color: #6c757d;
}

.custom-textarea:focus {
  border-color: #0b3357;
  box-shadow: 0 0 0 3px rgba(11, 51, 87, 0.1);
  outline: none;
}

.custom-textarea::placeholder {
  color: #a0aec0;
  font-size: 0.95rem;
  line-height: 1.5;
}

.audience-selector {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.audience-option {
  position: relative;
  background: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.audience-option .form-check {
  display: flex;
  align-items: flex-start;
  margin: 0;
  padding: 0;
}

.audience-option .form-check-input {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.option-content {
  width: 100%;
  padding: 0 2rem;
}

.option-header {
  font-weight: 500;
  color: #2d3748;
  font-size: 1rem;
}

.option-description {
  color: #718096;
  font-size: 0.9rem;
}

.audience-option:hover {
  background: #f1f5f9;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.audience-option.active {
  border-color: #0b3357;
  background: #ebf4ff;
}

.audience-option.active:hover {
  background: #e2efff;
}

.audience-option .form-check {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.audience-option .form-check-input {
  margin-right: 0.75rem;
  cursor: pointer;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}

.audience-option .form-check-label {
  flex: 1;
}

.audience-title {
  font-weight: 500;
  color: #2d3748;
  font-size: 1rem;
  display: block;
}

.audience-description {
  display: block;
  color: #718096;
  margin-top: 0.25rem;
  margin-left: 1.75rem;
  font-size: 0.9rem;
}

.custom-input-wrapper {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.custom-input {
  width: 100%;
  max-width: 90%;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 0.9rem;
  margin: 0 auto;
}

.custom-input:focus {
  border-color: #0b3357;
  box-shadow: 0 0 0 1px #0b3357;
  outline: none;
}

.custom-input::placeholder {
  color: #a0aec0;
}

.objective-options {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin: 0 auto;
}

.objective-option {
  background: #f8fafc;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  padding: 1rem;
  transition: all 0.2s ease;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}

.objective-option:hover {
  background: #f1f5f9;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.objective-option.active {
  border-color: #0b3357;
  background: #ebf4ff;
}

.objective-option.active:hover {
  background: #e2efff;
}

.option-icon {
  font-size: 1.5rem;
  min-width: 2rem;
  text-align: center;
  margin-top: 0.25rem;
}

.option-content {
  flex: 1;
}

.objective-option .form-check {
  margin: 0;
  padding: 0;
}

.objective-option .form-check-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.objective-option .form-check-label {
  font-size: 1.1rem;
  font-weight: 600;
  color: #4a5568;
  margin-bottom: 0.25rem;
  cursor: pointer;
  display: block;
}

@media (max-width: 768px) {
  .audience-selector {
    grid-template-columns: 1fr;
  }

  .audience-option {
    padding: 0.75rem;
  }

  .custom-textarea {
    font-size: 0.95rem;
  }

  .section-subtitle {
    font-size: 0.9rem;
  }

  .objective-option {
    padding: 0.75rem;
  }

  .option-icon {
    font-size: 1.25rem;
  }

  .objective-option .form-check-label {
    font-size: 1rem;
  }

  .option-description {
    font-size: 0.85rem;
  }
}

@media (max-width: 480px) {
  .step-number {
    width: 24px;
    height: 24px;
    font-size: 0.8rem;
  }

  .step-title {
    font-size: 0.7rem;
  }

  .form-section-title {
    font-size: 1.1rem;
  }

  .form-check {
    padding: 0.5rem;
  }

  .form-label {
    font-size: 0.9rem;
  }
}

/* Step 2 Styles */
.step2-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
}

/* .form-section {
  margin-bottom: 2rem;
} */

.section-title {
  font-size: 1.5rem;
  color: #2d3748;
  margin: 0;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
}

/* Currency Selector */
.currency-selector {
  margin-bottom: 1.5rem;
}

.currency-options {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.currency-option {
  background: #f8fafc;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  padding: 0.75rem 1.5rem;
  transition: all 0.2s ease;
  cursor: pointer;
  user-select: none;
}

.currency-option:hover {
  background: #f1f5f9;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.currency-option.active {
  border-color: #0b3357;
  background: #ebf4ff;
}

.currency-option.active:hover {
  background: #e2efff;
}

.currency-option .form-check {
  margin: 0;
  padding: 0;
}

.currency-option .form-check-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.currency-option .form-check-label {
  display: block;
  margin: 0;
  cursor: pointer;
}

.currency-option .form-check-label {
  font-size: 1.25rem;
  font-weight: 600;
  color: #4a5568;
  cursor: pointer;
}

.currency-option.active .form-check-label {
  color: #0b3357;
}

.currency-option.is-invalid {
  border-color: #dc3545;
}

/* Amount Input */
.amount-input-container {
  margin-top: 1rem;
}

.amount-wrapper {
  position: relative;
  max-width: 300px;
  margin: 0 auto;
}

.currency-symbol {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.25rem;
  color: #4a5568;
  font-weight: 600;
}

.amount-input {
  height: 50px;
  font-size: 1.25rem;
  padding: 0.5rem 1rem 0.5rem 3rem;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  text-align: right;
  font-weight: 600;
}

.amount-input:focus {
  border-color: #0b3357;
  box-shadow: 0 0 0 0.2rem rgba(11, 51, 87, 0.25);
}

.amount-input.is-invalid {
  border-color: #dc3545;
}

/* Payment Options */
.payment-options {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-width: 400px;
  margin: 0 auto;
}

.payment-option {
  background: #f8fafc;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  padding: 1rem;
  transition: all 0.2s ease;
  cursor: pointer;
  user-select: none;
}

.payment-option:hover {
  background: #f1f5f9;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.payment-option.active {
  border-color: #0b3357;
  background: #ebf4ff;
}

.payment-option.active:hover {
  background: #e2efff;
}

.payment-option .form-check {
  margin: 0;
  padding: 0;
  justify-content: center;
  text-align: center;
}

.payment-option .form-check-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.payment-option .form-check-label {
  display: block;
  margin: 0;
  cursor: pointer;
  text-align: center;
}

.payment-option .form-check-label {
  font-size: 1rem;
  font-weight: 600;
  color: #4a5568;
  margin-bottom: 0.25rem;
  cursor: pointer;
}

.payment-option.is-invalid {
  border-color: #dc3545;
}

.option-description {
  display: block;
  color: #718096;
  font-size: 0.85rem;
}

/* Months Input Section */
.months-input-section {
  margin-top: 1.5rem;
  max-width: 400px;
  margin: 1.5rem auto 0;
}

.months-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.months-input-wrapper .form-control {
  height: 40px;
  font-size: 1rem;
  text-align: center;
  border: 2px solid #e2e8f0;
  border-radius: 10px;
}

.months-label {
  color: #4a5568;
  font-size: 1rem;
}

.months-input-wrapper .form-control.is-invalid {
  border-color: #dc3545;
}

/* Payment Summary */
.payment-summary {
  margin-top: 1rem;
  background: #f8fafc;
  border-radius: 12px;
  padding: 1rem;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.summary-item:last-child {
  margin-bottom: 0;
  padding-top: 0.5rem;
  border-top: 1px solid var(--border-color-default);
}

.summary-item span {
  color: #718096;
}

.summary-item strong {
  color: #2d3748;
  font-size: 1rem;
}

/* Error Messages */
.error-message {
  color: #dc3545;
  font-size: 0.8rem;
  margin-top: 0.25rem;
  text-align: center;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .ad-request-form-container {
    padding: 0;
    margin: 20px 0;
    width: 100vw;
    max-width: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-bottom: 100px !important;
  }

  .ad-request-form {
    margin: 0;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .form-content {
    padding: 0.5rem;
  }

  textarea,
  input[type="text"],
  .form-control {
    width: 100%;
    margin: 0;
    border-radius: 4px;
  }
}

@media screen and (max-width: 768px) {
  .ad-request-form-container {
    padding: 0;
    margin: 20px 0;
    margin-top: 40px;
    max-width: 100%;
    width: 100vw;
  }

  .form-progress-bar {
    padding: 1rem;
  }

  .progress-steps-container {
    gap: 10px;
  }

  .step-item {
    max-width: none;
  }

  .step-title {
    font-size: 0.8rem;
  }

  .btn-navigation {
    padding: 0.5rem 1rem;
  }

  .currency-input-group {
    flex-direction: column;
    gap: 0.5rem;
  }

  .currency-input-group select,
  .currency-input-group input {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .step-number {
    width: 24px;
    height: 24px;
    font-size: 0.8rem;
  }

  .step-title {
    display: none !important;
  }

  .form-section-title {
    font-size: 1.1rem;
  }

  .form-check {
    padding: 0.5rem;
  }

  .form-label {
    font-size: 0.9rem;
  }
}

/* Step 3 Styles */
.step3-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
}

/* .form-section {
  margin-bottom: 2rem;
} */

.section-title {
  font-size: 1.5rem;
  color: #2d3748;
  margin: 0;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
}

/* Currency Selector */
.currency-selector {
  margin-bottom: 1.5rem;
}

.currency-options {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.currency-option {
  background: #f8fafc;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  padding: 0.75rem 1.5rem;
  transition: all 0.2s ease;
  cursor: pointer;
  user-select: none;
}

.currency-option:hover {
  background: #f1f5f9;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.currency-option.active {
  border-color: #0b3357;
  background: #ebf4ff;
}

.currency-option.active:hover {
  background: #e2efff;
}

.currency-option .form-check {
  margin: 0;
  padding: 0;
}

.currency-option .form-check-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.currency-option .form-check-label {
  display: block;
  margin: 0;
  cursor: pointer;
}

.currency-option .form-check-label {
  font-size: 1.25rem;
  font-weight: 600;
  color: #4a5568;
  cursor: pointer;
}

.currency-option.active .form-check-label {
  color: #0b3357;
}

.currency-option.is-invalid {
  border-color: #dc3545;
}

/* Amount Input */
.amount-input-container {
  margin-top: 1rem;
}

.amount-wrapper {
  position: relative;
  max-width: 300px;
  margin: 0 auto;
}

.currency-symbol {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.25rem;
  color: #4a5568;
  font-weight: 600;
}

.amount-input {
  height: 50px;
  font-size: 1.25rem;
  padding: 0.5rem 1rem 0.5rem 3rem;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  text-align: right;
  font-weight: 600;
}

.amount-input:focus {
  border-color: #0b3357;
  box-shadow: 0 0 0 0.2rem rgba(11, 51, 87, 0.25);
}

.amount-input.is-invalid {
  border-color: #dc3545;
}

/* Payment Options */
.payment-options {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-width: 400px;
  margin: 0 auto;
}

.payment-option {
  background: #f8fafc;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  padding: 1rem;
  transition: all 0.2s ease;
  cursor: pointer;
  user-select: none;
}

.payment-option:hover {
  background: #f1f5f9;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.payment-option.active {
  border-color: #0b3357;
  background: #ebf4ff;
}

.payment-option.active:hover {
  background: #e2efff;
}

.payment-option .form-check {
  margin: 0;
  padding: 0;
  justify-content: center;
  text-align: center;
}

.payment-option .form-check-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.payment-option .form-check-label {
  display: block;
  margin: 0;
  cursor: pointer;
  text-align: center;
}

.payment-option .form-check-label {
  font-size: 1rem;
  font-weight: 600;
  color: #4a5568;
  margin-bottom: 0.25rem;
  cursor: pointer;
}

.payment-option.is-invalid {
  border-color: #dc3545;
}

.option-description {
  display: block;
  color: #718096;
  font-size: 0.85rem;
}

/* Months Input Section */
.months-input-section {
  margin-top: 1.5rem;
  max-width: 400px;
  margin: 1.5rem auto 0;
}

.months-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.months-input-wrapper .form-control {
  height: 40px;
  font-size: 1rem;
  text-align: center;
  border: 2px solid #e2e8f0;
  border-radius: 10px;
}

.months-label {
  color: #4a5568;
  font-size: 1rem;
}

.months-input-wrapper .form-control.is-invalid {
  border-color: #dc3545;
}

/* Payment Summary */
.payment-summary {
  margin-top: 1rem;
  background: #f8fafc;
  border-radius: 12px;
  padding: 1rem;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.summary-item:last-child {
  margin-bottom: 0;
  padding-top: 0.5rem;
  border-top: 1px solid #e2e8f0;
}

.summary-item span {
  color: #718096;
}

.summary-item strong {
  color: #2d3748;
  font-size: 1rem;
}

/* Error Messages */
.error-message {
  color: #dc3545;
  font-size: 0.8rem;
  margin-top: 0.25rem;
  text-align: center;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .ad-request-form-container {
    padding: 0;
    margin: 20px 0;
    width: 100vw;
    max-width: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ad-request-form {
    margin: 0;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }

  .form-content {
    padding: 0.5rem;
  }

  textarea,
  input[type="text"],
  .form-control {
    width: 100%;
    margin: 0;
    border-radius: 4px;
  }
}

@media screen and (max-width: 768px) {
  .ad-request-form-container {
    padding: 0;
    margin: 20px 0;
    margin-top: 40px;
    max-width: 100%;
    width: 100vw;
  }

  .form-progress-bar {
    padding: 1rem;
  }

  .progress-steps-container {
    gap: 10px;
  }

  .step-item {
    max-width: none;
  }

  .step-title {
    font-size: 0.8rem;
  }

  .btn-navigation {
    padding: 0.5rem 1rem;
  }

  .currency-input-group {
    flex-direction: column;
    gap: 0.5rem;
  }

  .currency-input-group select,
  .currency-input-group input {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .step-number {
    width: 24px;
    height: 24px;
    font-size: 0.8rem;
  }

  .step-title {
    display: none !important;
  }

  .form-section-title {
    font-size: 1.1rem;
  }

  .form-check {
    padding: 0.5rem;
  }

  .form-label {
    font-size: 0.9rem;
  }
}

/* Step 4 Styles */
.contact-form {
  max-width: 800px;
  margin: 0 auto;
}

.form-field {
  margin-bottom: 1rem;
}

.form-field label {
  font-weight: 500;
  color: #4a5568;
  margin-bottom: 0.5rem;
  display: block;
}

.custom-input {
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  transition: all 0.2s ease;
  background-color: #f8fafc;
}

.custom-input:focus {
  border-color: #0b3357;
  box-shadow: 0 0 0 3px rgba(11, 51, 87, 0.1);
  outline: none;
}

.custom-input::placeholder {
  color: #a0aec0;
}

.custom-input[type="date"] {
  cursor: pointer;
}

.radio-group {
  display: flex;
  gap: 1.5rem;
  margin-top: 0.5rem;
}

.custom-radio {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-radio .form-check-input {
  margin-right: 0.5rem;
  cursor: pointer;
}

.custom-radio .form-check-input:checked {
  background-color: #0b3357;
  border-color: #0b3357;
}

.custom-radio .form-check-label {
  cursor: pointer;
  user-select: none;
}

.terms-section {
  margin-top: 1rem;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 12px;
  border: 2px solid #e2e8f0;
}

.custom-checkbox {
  display: flex;
  align-items: center;
}

.custom-checkbox .form-check-input {
  margin-right: 0.75rem;
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
}

.custom-checkbox .form-check-input:checked {
  background-color: #0b3357;
  border-color: #0b3357;
}

.terms-label {
  font-size: 0.95rem;
  color: #4a5568;
  cursor: pointer;
  user-select: none;
}

@media (max-width: 768px) {
  .radio-group {
    flex-direction: column;
    gap: 0.75rem;
  }

  .custom-input {
    font-size: 0.95rem;
    padding: 0.5rem 0.75rem;
  }

  .terms-section {
    padding: 0.75rem;
  }
}

/* Estilos para Step3 */
.departamentos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 0.75rem;
  margin: 1rem 0;
}

.departamento-card {
  position: relative;
  background: white;
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-small);
  padding: 0.75rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
}

.departamento-card:hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-color: #0b3357;
}

.departamento-card.active {
  border-color: #0b3357;
  background: #ebf5ff;
}

.select-indicator {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #e2e8f0;
  color: #718096;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  transition: all 0.2s ease;
}

.departamento-card.active .select-indicator {
  background: #0b3357;
  color: white;
}

.selected-departments {
  margin-top: 2rem;
}

.department-cities {
  margin-bottom: 2rem;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 12px;
  padding: 1.5rem;
}

.department-cities h4 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
  color: #2d3748;
}

.department-icon {
  font-size: 1.2rem;
}

.budget-distribution-section {
  background: #fff;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.budget-header {
  margin-bottom: 1.5rem;
}

.budget-info {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.budget-title {
  text-align: center;
  margin-bottom: 1rem;
}

.budget-title h3 {
  font-size: 1.25rem;
  margin: 0;
  color: #2d3748;
}

.distribution-options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem 0;
  border-top: 1px solid #e2e8f0;
  border-bottom: 1px solid var(--border-color-default);
}

.option-group {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.option-group .form-check {
  margin: 0;
  padding-left: 2rem;
}

.option-group .form-check-label {
  font-size: 0.95rem;
  color: #4a5568;
}

.distribution-actions {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
  padding: 0.5rem;
  background: #f8fafc;
  border-radius: 8px;
}

.distribute-btn {
  font-size: 0.875rem;
  padding: 0.375rem 1rem;
  border-radius: 20px;
  border: 1px solid #e9ecef;
  background-color: #f8f9fa;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.distribute-btn:hover:not(:disabled) {
  background-color: #e9ecef;
  border-color: #dee2e6;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.distribute-btn:active:not(:disabled) {
  transform: translateY(0);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.distribute-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.button-icon {
  font-size: 1.1rem;
}

.distribute-icon {
  font-size: 1rem;
  line-height: 1;
}

.distribute-text {
  font-weight: 500;
  color: #495057;
}

@media (min-width: 768px) {
  .distribute-btn {
    padding: 0.375rem 1.25rem;
  }
}

.budget-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 0.5rem;
}

.budget-summary {
  background: #f8fafc;
  padding: 1rem 1.5rem;
  border-radius: 8px;
  margin: 0 auto;
  min-width: 250px;
  max-width: 450px;
}

.budget-amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.budget-amount:last-child {
  margin-bottom: 0;
  padding-top: 0.5rem;
  border-top: 1px solid #e2e8f0;
}

.budget-amount .label {
  color: #64748b;
  font-size: 0.9rem;
}

.budget-amount .amount {
  font-weight: 600;
  color: #2d3748;
}

.budget-amount .amount.negative {
  color: #e53e3e;
}

.budget-progress {
  margin: 0 auto;
  width: 100%;
  max-width: 300px;
}

.custom-progress {
  height: 0.5rem;
  border-radius: 999px;
}

.progress-label {
  display: block;
  text-align: center;
  color: #64748b;
  margin-top: 0.5rem;
  font-size: 0.85rem;
}

.department-cities {
  background: #f8fafc;
  border-radius: 8px;
  padding: 1.25rem;
  margin-bottom: var(--margin-bottom-default);
}

.department-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.department-header h4 {
  margin: 0;
  color: #2d3748;
  font-size: 1.1rem;
}

.department-budget {
  width: 200px;
}

.input-group-text {
  background-color: #f1f5f9;
  border-color: #e2e8f0;
  color: #64748b;
  font-size: 0.9rem;
}

.cities-grid {
  margin-top: var(--margin-top-default);
}

.city-budget-item {
  margin-bottom: 1rem;
}

.city-budget-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.city-info {
  flex: 1;
}

.city-info .form-label {
  margin: 0;
  color: #4a5568;
  font-size: 0.95rem;
}

.amount-input-container {
  width: 160px;
}

.media-section {
  margin-top: 3rem;
}

.media-content {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
}

.media-text {
  flex: 1;
}

.media-description {
  display: block;
  color: #718096;
  margin-top: 0.25rem;
  margin-left: var(--margin-left-indent);
}

.media-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1rem;
  margin-top: 1.5rem;
}

.media-card {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  background: white;
}

.media-card:hover {
  border-color: #3182ce;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.media-card.active {
  border-color: #3182ce;
  background-color: #ebf8ff;
}

.media-checkbox {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.media-icon {
  font-size: 1.5rem;
  margin-right: 1rem;
  flex-shrink: 0;
}

.media-content {
  flex: 1;
  padding-right: 2rem;
}

.media-title {
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 0.25rem;
}

.media-description {
  color: #718096;
  font-size: 0.9rem;
  line-height: 1.4;
}

@media screen and (max-width: 768px) {
  .media-grid {
    grid-template-columns: 1fr;
    gap: 0.75rem;
    margin: 0.75rem 0.5rem;
  }

  .media-card {
    padding: 0.875rem;
    margin-bottom: 0.5rem;
  }

  .media-checkbox {
    top: 50%;
    transform: translateY(-50%);
  }

  .media-icon {
    font-size: 1.25rem;
    margin-right: 0.75rem;
  }

  .media-content {
    padding-right: 2.5rem;
  }

  .media-title {
    font-size: 0.95rem;
  }

  .media-description {
    font-size: 0.85rem;
  }
}

@media screen and (max-width: 480px) {
  .media-grid {
    margin: 0.5rem 0;
  }

  .media-card {
    padding: 0.75rem;
    border-radius: 6px;
  }

  .media-icon {
    font-size: 1.1rem;
    margin-right: 0.625rem;
  }

  .media-content {
    padding-right: 2rem;
  }

  .media-title {
    font-size: 0.9rem;
    margin-bottom: 0.125rem;
  }

  .media-description {
    font-size: 0.8rem;
    line-height: 1.3;
  }
}

.info-card {
  background: white;
  border: 1px solid var(--border-color-default);
  border-radius: var(--border-radius-medium);
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.info-card-header {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.info-card-icon {
  font-size: 1.5rem;
  line-height: 1;
}

.info-card-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #2d3748;
}

.custom-textarea {
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 0.75rem;
  font-size: 0.95rem;
  width: 100%;
  resize: vertical;
  min-height: 120px;
  transition: all 0.2s ease;
}

.custom-textarea:focus {
  border-color: #3182ce;
  box-shadow: 0 0 0 1px #3182ce;
}

.radio-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-top: 0.5rem;
}

.radio-card {
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  display: flex;
  align-items: flex-start;
}

.radio-card:hover {
  border-color: #3182ce;
  transform: translateY(-1px);
}

.radio-card.active {
  border-color: #3182ce;
  background: #ebf8ff;
}

.radio-card-content {
  flex: 1;
}

.radio-card-icon {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.radio-card-label {
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 0.25rem;
}

.radio-card-description {
  font-size: 0.85rem;
  color: #718096;
}

.radio-card-input {
  margin: 0;
}

.date-field {
  margin-bottom: 1rem;
}

.date-field label {
  display: block;
  margin-bottom: 0.5rem;
  color: #2d3748;
  font-weight: 500;
}

.date-field small {
  display: block;
  margin-top: 0.25rem;
  color: #718096;
}

.custom-input[type="date"] {
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 0.5rem;
  width: 100%;
  font-size: 0.95rem;
}

.custom-input[type="date"]:focus {
  border-color: #3182ce;
  box-shadow: 0 0 0 1px #3182ce;
}

@media (max-width: 768px) {
  .ad-request-form-container {
    padding: 0;
    margin: 20px 0;
    margin-top: 40px;
    max-width: 100%;
    width: 100vw;
  }

  .ad-request-form {
    width: 100%;
    margin: 0;
    border-radius: 8px;
  }

  /* Ajustes para medios de difusión */
  .media-options-grid {
    grid-template-columns: 1fr; /* Una columna en móvil */
    gap: 0.5rem;
  }

  .media-option {
    padding: 0.75rem;
    flex-direction: column;
    align-items: flex-start;
  }

  .media-option-icon {
    margin-bottom: 0.5rem;
  }

  .media-option-content {
    width: 100%;
  }

  .media-option-title {
    font-size: 0.9rem;
    margin-bottom: 0.25rem;
  }

  .media-option-description {
    font-size: 0.8rem;
  }

  .media-option-price {
    font-size: 0.9rem;
    margin-top: 0.5rem;
  }
}

@media screen and (max-width: 480px) {
  .ad-request-form-container {
    padding: 0; /* Eliminar padding en móvil */
    margin: 20px 0; /* Eliminar margen superior en móvil */
  }

  .ad-request-form {
    border-radius: 0; /* Eliminar bordes redondeados en móvil */
  }

  .media-option {
    padding: 0.5rem;
  }

  .media-option-title {
    font-size: 0.85rem;
  }

  .media-option-description {
    font-size: 0.75rem;
  }

  .media-option-price {
    font-size: 0.85rem;
  }
}

/* Estilos para la sección de medios de difusión */
.media-options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
}

.media-option {
  display: flex;
  flex-direction: column;
}

.media-option .form-check {
  margin: 0;
  padding: 0;
}

.media-option .form-check-input {
  margin-right: 0.75rem;
}

.media-title {
  font-weight: 500;
  color: #2d3748;
  margin-left: 0.5rem;
}

.media-description {
  display: block;
  color: #718096;
  margin-top: calc(var(--margin-top-default) / 4); /* 0.25rem / 1rem */
  margin-left: var(--margin-left-indent);
  font-size: 0.9rem;
}

.custom-input-wrapper {
  margin-top: 0.75rem;
  margin-left: var(--margin-left-indent);
  transition: all 0.3s ease;
}

.custom-input {
  width: 100%;
  max-width: 400px;
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 0.9rem;
}

.custom-input:focus {
  border-color: #3182ce;
  box-shadow: 0 0 0 1px #3182ce;
  outline: none;
}

@media screen and (max-width: 768px) {
  .media-options {
    gap: 0.75rem;
  }

  .media-title {
    font-size: 0.95rem;
  }

  .media-description {
    font-size: 0.85rem;
    margin-left: 1.5rem;
  }

  .custom-input-wrapper {
    margin-left: 1.5rem;
  }
}

@media screen and (max-width: 480px) {
  .media-options {
    gap: 0.5rem;
  }

  .media-title {
    font-size: 0.9rem;
  }

  .media-description {
    font-size: 0.8rem;
    margin-left: 1.5rem;
  }

  .custom-input {
    padding: 0.375rem;
    font-size: 0.85rem;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f8faff;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #1b74c5;
  border-radius: 4px;
  opacity: 0.8;
}

::-webkit-scrollbar-thumb:hover {
  background: #3876b5;
}

@media (max-width: 768px) {
  .ad-request-form-container {
    padding: 0;
    margin: 20px 0;
    width: 100vw;
    max-width: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .ad-request-form {
    margin: 0;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }

  textarea,
  input[type="text"],
  .form-control {
    width: 100%;
    margin: 0;
    border-radius: 4px;
  }
}

.mt-4 {
  padding: 6px 12px;
  background: #f8fafc;
  border-top: 1px solid var(--border-color-default);
}

/* Common option styles for Step1 and Step2 */
.audience-options,
.media-options {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
}

.audience-option {
  background: #ffffff;
  border: 2px solid #e2e8f0;
  border-radius: 12px;
  padding: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.audience-option:hover {
  border-color: #cbd5e0;
  background-color: #f8fafc;
  transform: translateY(-1px);
}

.audience-option.active {
  border-color: #0b3357;
  background-color: rgba(11, 51, 87, 0.03);
}

.option-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.option-header {
  font-weight: 500;
  color: #2d3748;
  font-size: 0.95rem;
  display: flex;
  align-items: center;
  gap: 8px;
}

.option-description {
  color: #718096;
  font-size: 0.9rem;
  line-height: 1.4;
  margin-left: 28px;
}

.custom-input-wrapper {
  margin-top: 12px;
  margin-left: 28px;
  padding-right: 12px;
  width: calc(100% - 40px);
}

.custom-input {
  background-color: #ffffff !important;
  border: 2px solid #e2e8f0 !important;
  border-radius: 8px !important;
  padding: 10px 16px !important;
  font-size: 0.95rem !important;
  width: 100% !important;
  transition: all 0.2s ease !important;
  color: #2d3748 !important;
}

.custom-input:focus {
  border-color: #0b3357 !important;
  box-shadow: 0 0 0 3px rgba(11, 51, 87, 0.1) !important;
  outline: none !important;
}

.custom-input::placeholder {
  color: #a0aec0 !important;
  font-size: 0.9rem !important;
}

/* Ensure the input doesn't trigger the parent's hover effect */
.audience-option .custom-input-wrapper {
  position: relative;
  z-index: 2;
}

.audience-option:hover .custom-input {
  border-color: #e2e8f0 !important;
}

.audience-option .custom-input:focus {
  border-color: #0b3357 !important;
}

.section-subtitle {
  color: #6c757d;
  font-size: 0.875rem;
  margin-top: -1rem;
}

/* Form check styling */
.form-check {
  margin: 0 !important;
  padding: 0 !important;
  display: flex !important;
  align-items: flex-start !important;
}

.form-check-input {
  margin: 0 !important;
  margin-right: 12px !important;
  margin-top: 4px !important;
}

.form-check-label {
  margin: 0 !important;
  padding: 0 !important;
  cursor: pointer !important;
  flex: 1 !important;
}

/* Optimizaciones para dispositivos móviles */
@media (max-width: 768px) {
  .form-control,
  input[type="text"],
  input[type="number"],
  input[type="date"],
  select,
  textarea {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    touch-action: manipulation;
    -webkit-appearance: none;
    appearance: none;
    font-size: 16px; /* Previene el zoom automático en iOS */
  }

  .form-check {
    touch-action: manipulation;
    -webkit-tap-highlight-color: transparent;
    padding: 12px; /* Área táctil más grande */
  }

  select {
    cursor: pointer;
    background-color: transparent;
  }
}

/* Mejoras en el rendimiento de las animaciones */
input,
select,
textarea {
  backface-visibility: hidden;
  transform: translateZ(0);
  -webkit-font-smoothing: antialiased;
}

/* Ocultar flechas del input numérico */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Estilos para FormStep4 */
.localidades-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.budget-display {
  background-color: #f8f9fa;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-weight: 500;
}

.progress-section {
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.custom-switch {
  padding: 0.5rem;
  background-color: #fff;
  border-radius: 0.25rem;
  transition: background-color 0.2s;
}

.custom-switch:hover {
  background-color: #f8f9fa;
}

@media (min-width: 768px) {
  .locations-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

.section-title {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: #2c3e50;
}

.section-description {
  color: #6c757d;
  font-size: 0.875rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.info-box {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border: 1px solid #e9ecef;
}

.distribution-section {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #e9ecef;
}

.distribution-btn {
  white-space: nowrap;
  padding: 0.375rem 1rem;
}

.distribution-switch {
  padding: 0.75rem;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #e9ecef;
  margin: 0;
  transition: all 0.2s ease;
}

.distribution-switch:hover {
  background-color: #f8f9fa;
}

.locations-grid {
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
}

@media (min-width: 576px) {
  .locations-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  .locations-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.location-item {
  background-color: #fff;
  padding: 0.75rem;
  border-radius: var(--border-radius-small);
  border: 1px solid var(--border-color-default);
}

.location-item .form-label {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}

.location-item .form-control {
  font-size: 0.875rem;
  padding: 0.375rem 0.5rem;
}

.section-title {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  color: #2c3e50;
}

.section-description {
  color: #6c757d;
  font-size: 0.875rem;
  text-align: center;
  margin-top: -1rem;
  margin-bottom: 2rem;
}

.info-box {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  border: 1px solid #e9ecef;
}

.distribution-section {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #e9ecef;
}

.locations-container {
  margin-top: 1rem;
}

.locations-title {
  font-size: 1rem;
  color: #2c3e50;
  margin-bottom: 0;
}

.locations-grid {
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
}

@media (min-width: 576px) {
  .locations-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 992px) {
  .locations-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.location-item {
  background-color: #fff;
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid #e9ecef;
  transition: all 0.2s ease;
}

.location-item:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-color: #dee2e6;
}

.location-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: calc(var(--margin-bottom-default) * 0.75);
}

.location-name {
  font-weight: 500;
  color: #2c3e50;
}

.distribution-switch {
  padding: 0.75rem;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #e9ecef;
  margin: 0;
  transition: all 0.2s ease;
}

.distribution-switch:hover {
  background-color: #f8f9fa;
}

.budget-info-box {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border: 1px solid #e9ecef;
}

.distribution-option {
  margin: 1rem 0;
}

.locations-section {
  margin-top: 1.5rem;
}

.locations-header {
  margin-bottom: 1.5rem;
}

.locations-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.location-card {
  background-color: #fff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 1rem;
  transition: all 0.2s ease;
}

.location-card:hover {
  border-color: #dee2e6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.location-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}

.location-name {
  font-weight: 500;
  color: #2c3e50;
}

.location-selector {
  border-color: #198754;
  color: #198754;
  transition: all 0.2s ease;
}

.location-selector:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.2rem rgba(25, 135, 84, 0.25);
}

.distribution-switch {
  padding: 0.75rem;
  background-color: #fff;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  margin-bottom: 1rem;
  transition: all 0.2s ease;
}

.distribution-switch:hover {
  background-color: #f8f9fa;
}

@media (min-width: 768px) {
  .locations-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

.input-currency-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.currency-symbol {
  position: absolute;
  left: 12px;
  color: #495057;
  z-index: 2;
  font-size: 0.9rem;
}

.input-currency-wrapper .form-control {
  padding-left: 32px;
}

/* Estilos para los selectores de material */
.material-details {
  background: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  padding: 15px;
  margin-top: 15px;
  border: 1px solid #e0e0e0;
  transition: all 0.3s ease;
}

.material-type-options {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 10px;
  margin-top: 10px;
}

.material-type-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background: white;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.material-type-option:hover {
  border-color: var(--primary-color);
  transform: translateY(-2px);
}

.material-type-option.active {
  border-color: var(--primary-color);
  background-color: var(--primary-color-light);
}

.material-type-icon {
  font-size: 24px;
  margin-bottom: 8px;
}

.material-type-label {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.duration-options {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}

.duration-option {
  padding: 8px 16px;
  background: white;
  border: 2px solid #e0e0e0;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.duration-option:hover {
  border-color: var(--primary-color);
}

.duration-option.active {
  border-color: var(--primary-color);
  background-color: var(--primary-color-light);
  color: var(--primary-color);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .material-type-options {
    grid-template-columns: repeat(2, 1fr);
  }

  .duration-options {
    flex-direction: column;
  }

  .duration-option {
    width: 100%;
    text-align: center;
  }
}

.success-message {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
  padding: 1rem;
}

.success-message .alert {
  background: white;
  border: none;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  margin-bottom: 0;
  max-width: 500px;
  width: 100%;
  padding: 2rem;
  border-radius: 12px;
  animation: fadeInScale 0.3s ease-out;
}

@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.success-message .alert-heading {
  color: #1b74c5;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 1.8em;
  margin-bottom: 1rem;
  text-align: center;
}

.success-message p {
  font-family: "Montserrat", sans-serif;
  color: #333;
  margin-bottom: 0.5rem;
  font-size: 1.1em;
  text-align: center;
  line-height: 1.5;
}

.media-options .audience-option.is-invalid {
  border-color: #dc3545;
}

.media-options .audience-option.is-invalid:hover {
  border-color: #dc3545;
}

.media-options .audience-option .custom-input.is-invalid {
  border-color: #dc3545;
}

.btn-custom-primary {
  background-color: transparent;
  border-color: #0b3357;
  color: #0b3357;
}

.btn-custom-primary:hover {
  background-color: #0b3357;
  color: white;
}

.progress-bar-custom {
  background-color: #0b3357;
}

@media (min-width: 768px) {
  .progress-bar-top {
    display: block;
  }
}

@media (min-width: 768px) {
  .mt-4 {
    display: none;
  }
}
