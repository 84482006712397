.contact-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 500px;
  margin: 0 auto;
}

.contact-form input,
.contact-form textarea {
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.contact-form textarea {
  min-height: 150px;
  resize: vertical;
}

.contact-form button {
  padding: 1rem 2rem;
  background-color: #0b3357;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #0d4070;
}
