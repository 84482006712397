.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
  margin-top: 60px;
  flex-direction: column;
  transition: background-color 0.5s ease;
  font-family: "Montserrat", sans-serif;
  overflow: hidden;
  padding: 60px 20px;
  background: linear-gradient(
    135deg,
    #072c4e 0%,
    #0b3357 50%,
    #083461 100%
  );
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle at center, rgba(11, 51, 87, 0) 0%, rgba(7, 44, 78, 0.8) 100%);
  pointer-events: none;
  z-index: 1;
}

.animated-text-container {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
  width: 90%;
  max-width: 800px;
}

.centered-text {
  display: inline-block;
}

.centered-text h1 {
  font-size: 4.5rem;
  font-weight: 700;
  margin: 1rem 0;
  background: linear-gradient(45deg, #ffffff, #e0e0e0);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 2px 10px rgba(255, 255, 255, 0.1);
}

.buttons-container {
  position: absolute;
  bottom: 140px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: 2;
  width: 90%;
  max-width: 300px;
}

.cta-button {
  width: 100%;
  padding: 16px 32px;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #ffffff;
  background: linear-gradient(110deg, #1a75ff, #0056b3, #003d80);
  background-size: 200% 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 
    0 4px 15px rgba(0, 123, 255, 0.2),
    0 0 0 1px rgba(255, 255, 255, 0.1) inset;
}

.cta-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(110deg, 
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.05),
    rgba(255, 255, 255, 0)
  );
  opacity: 0;
  transition: opacity 0.4s ease;
}

.cta-button::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  right: -50%;
  bottom: -50%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  transform: rotate(45deg);
  transition: 0.5s;
  opacity: 0;
}

.cta-button span {
  position: relative;
  z-index: 1;
  font-weight: 500;
  display: block;
  transform: translateY(0);
  transition: transform 0.3s ease;
  color: #ffffff;
}

.cta-button:hover {
  background-position: 100% 0;
  transform: translateY(-2px);
  box-shadow: 
    0 6px 20px rgba(0, 123, 255, 0.3),
    0 0 0 1px rgba(255, 255, 255, 0.2) inset;
}

.cta-button:hover::before {
  opacity: 1;
}

.cta-button:hover::after {
  opacity: 1;
  left: 150%;
  transition: 0.5s ease-in-out;
}

.cta-button:hover span {
  transform: translateY(-1px);
  color: #ffffff;
}

.cta-button:active {
  transform: translateY(0);
  box-shadow: 
    0 2px 10px rgba(0, 123, 255, 0.2),
    0 0 0 1px rgba(255, 255, 255, 0.15) inset;
  transition-duration: 0.1s;
}

@media (max-width: 768px) {
  .cta-button {
    padding: 14px 28px;
    font-size: 0.95rem;
    letter-spacing: 1.2px;
  }
}

.scroll-indicator {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  padding: 8px;
  border-radius: 24px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  animation: bounce 2s infinite;
  min-width: 44px;
  overflow: hidden;
}

.scroll-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
}

.scroll-arrow {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 1;
  transform: translateY(0) scale(1);
}

.scroll-arrow svg {
  width: 32px;
  height: 32px;
  fill: white;
  filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.3));
}

.scroll-text {
  position: absolute;
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.scroll-indicator:hover {
  min-width: 120px;
  background: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.3);
  animation: none;
  transform: translateX(-50%) translateY(-2px);
}

.scroll-indicator:hover .scroll-arrow {
  opacity: 0;
  transform: translateY(-10px) scale(0.8);
}

.scroll-indicator:hover .scroll-text {
  opacity: 1;
  transform: translateY(0);
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  40% {
    transform: translateX(-50%) translateY(-10px);
  }
  60% {
    transform: translateX(-50%) translateY(-5px);
  }
}

@media (max-width: 768px) {
  .hero {
    padding: 0;
    margin: 0;
    overflow: hidden;
  }

  .centered-text h1 {
    font-size: 3rem;
  }
  
  .buttons-container {
    bottom: 140px;
  }
  
  .scroll-indicator {
    bottom: 40px;
    padding: 6px;
    min-width: 40px;
  }
  
  .scroll-indicator:hover {
    min-width: 110px;
  }

  .scroll-arrow svg {
    width: 28px;
    height: 28px;
  }
  
  .scroll-text {
    font-size: 0.85rem;
  }
}

.canvas {
  transition: opacity 0.3s ease-out;
}

.bold-text {
  font-weight: bold !important;
}

.form-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 800px;
  z-index: 2;
  padding: 20px;
}

.stars-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.4;
  pointer-events: none;
}

.constellation-line {
  stroke: rgba(255, 255, 255, 0.1);
  stroke-width: 1px;
}

.star {
  fill: rgba(255, 255, 255, 0.6);
  filter: blur(0.5px);
}

.star-glow {
  fill: rgba(255, 255, 255, 0.2);
  filter: blur(2px);
}
