 /* src/components/LandingPage.css */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

body {
  font-family: "Montserrat", sans-serif !important;
  margin: 0;
  padding: 0;
  color: #333;
  overflow-x: hidden;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
}

.landing-page {
  text-align: center;
}

.error {
  color: red;
}

.hero {
  background: white; /*linear-gradient(135deg, #155796, #155a9e);*/
  color: #3876b5;
  padding: 100px 20px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s forwards;
  height: 66.66vh; /* 2/3 del tamaño total del sitio */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden; /* Asegura que la animación no se desborde */
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

h1 {
  font-size: 3em;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  color: black !important;
  display: block;
  unicode-bidi: isolate;
}

.hero h1 {
  font-size: 3em;
  font-family: "Montserrat", sans-serif;
  margin: 0;
  color: white !important;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.8), 0 0 1px black, 0 0 1px black,
    0 0 1px black, 0 0 1px black;
  letter-spacing: 2px;
  display: block;
  margin-block-start: 0.3em;
  margin-block-end: 0.3em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
  font-weight: 800 !important;
}

.hero h2 {
  font-size: 1em;
  color: black;
  letter-spacing: 1px;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.7em;
  margin-bottom: 30px;
  font-weight: 400;
  z-index: 1;
}

.cta-button {
  background-color: #1b74c5;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.2em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.cta-button:hover {
  background-color: white;
  color: #1b74c5;
  transform: scale(1.05);
}

.service-section,
.testimonials-section,
.contact-section,
.what-we-offer-section,
.steps-section {
  padding: 50px 20px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s forwards;
  animation-delay: 0.5s;
  background: white;
  margin: 20px 0;
  border-radius: 10px;
}

.service-section h2,
.testimonials-section h2,
.contact-section h2,
.what-we-offer-section h2,
.steps-section h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.testimonial {
  background-color: #ffffff;
  padding: 20px;
  margin: 20px 0;
  border-radius: 5px;
  opacity: 0;
  transform: translateX(-100%);
  animation: slideInLeft 1s forwards;
  animation-delay: 1s;
}

@keyframes slideInLeft {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.testimonial p {
  font-style: italic;
  margin-bottom: 10px;
  font-size: 1.2em;
}

.contact-section form {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
}

.contact-section input,
.contact-section textarea {
  margin: 10px 0;
  padding: 15px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contact-section button {
  background-color: #1b74c5;
  color: white;
  border: none;
  padding: 15px;
  font-size: 1.2em;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-section button:hover {
  background-color: #155a9e;
  transform: scale(1.05);
}

/* Nueva animación para el formulario */
.ad-request-form-container {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s forwards;
  animation-delay: 0.2s;
}

/* Estilos para la sección "¿Qué ofrecemos?" */
.what-we-offer-section {
  padding: 50px 20px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s forwards;
  animation-delay: 0.5s;
  background: white;
  position: relative;
}

/* Estilos para la sección "¿Qué ofrecemos?" */
.steps-section {
  padding: 50px 20px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s forwards;
  animation-delay: 0.5s;
  background: white;
  position: relative;
}

.graph-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.graph-lines {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.graph-items {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
  z-index: 2;
}

.graph-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Posicionamiento específico para cada item */
.item-1 {
  margin-top: 0;
}

.item-2 {
  margin-top: 100px;
}

.item-3 {
  margin-top: 0;
}

.item-4 {
  margin-top: 100px;
}

.circle {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  font-weight: bold;
  color: white;
  background-color: #0b3357;
  box-shadow: 0 0 15px rgba(11, 51, 87, 0.5);
  margin-bottom: 1rem;
}

.black-text {
  color: black !important;
}

.line {
  stroke: rgba(11, 51, 87, 0.3);
  stroke-width: 1;
  fill: none;
}

.graph-item h3 {
  margin: 10px 0;
  font-size: 1.4em;
}

.graph-item p {
  margin: 0;
  font-size: 1em;
  line-height: 1.5;
}

.offer-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.offer-item {
  background: #f9f9f9;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  width: 30%;
  min-width: 200px;
  position: relative;
  overflow: hidden;
}

.offer-item:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.offer-item h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.offer-item h3::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background-image: url("/src/logo.png"); /* Reemplaza con la ruta de tu icono */
  background-size: cover;
}

.offer-item p {
  font-size: 1em;
  color: #666;
}

.offer-item:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9em;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s;
}

.offer-item:hover::after {
  opacity: 1;
}

.content-wrapper {
  position: relative;
  z-index: 2;
}

canvas {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  pointer-events: none;
  z-index: 1;
}

@media (max-width: 768px) {
  .offer-item {
    width: 100%;
    margin: 10px 0;
  }

  .offer-item h3::before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    background-image: none; /* Quita el logo */
  }

  .offer-item h3::before {
    content: "📢"; /* Emoticono de megáfono */
    font-size: 1.5em;
    margin-right: 10px;
  }

  .offer-item:nth-child(2) h3::before {
    content: "🔗"; /* Emoticono de enlace */
  }
}
.offer-item:nth-child(3) h3::before {
  content: "👁️"; /* Emoticono de ojo */
}

.offer-item:nth-child(4) h3::before {
  content: "📈"; /* Emoticono de gráfico */
}

.offer-item:nth-child(5) h3::before {
  content: "📊"; /* Emoticono de gráfico de barras */
}

.offer-item:nth-child(6) h3::before {
  content: "📣"; /* Emoticono de megafóno */
}

@media (max-width: 768px) {
  .graph-items {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
  .steps-section {
    padding: 50px 20px;
  }

  .item-1,
  .item-2,
  .item-3,
  .item-4 {
    margin-top: 0;
  }

  .item-1 {
    grid-row: 1;
  }
  .item-2 {
    grid-row: 2;
  }
  .item-3 {
    grid-row: 3;
  }
  .item-4 {
    grid-row: 4;
  }

  .graph-container {
    min-height: auto;
  }

  .graph-lines {
    display: none;
  }
}
